type Props = {
  url: string;
};

type ShareLink = {
    icon: string;
    label: string;
    url: string;
};

/**
 * Hardocded paths for the blog landing pages.
 *
 * @param Props
 * @returns string
 */
export default function useShareLinks({ url }: Props): Array<ShareLink> {
  const encodedUrl = encodeURIComponent(url);

  return [
    {
      label: 'Facebook share',
      icon: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
    },
    {
      label: 'Twitter Share',
      icon: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${encodedUrl}`
    },
    {
      label: 'LinkedIn Share',
      icon: 'Linkedin',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
    }
  ];
}
